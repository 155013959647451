var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'clickable-row': _vm.hasDefaultAction },attrs:{"headers":_vm.headersWithActions,"items":_vm.items,"loading":_vm.isBusy,"server-items-length":_vm.total,"footer-props":{
        showFirstLastPage: true,
        'items-per-page-options': _vm.availableItemsPerPage,
    },"options":_vm.options,"item-class":_vm.itemClass},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickItem},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),(_vm.showFilters())?{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"v-data-table-filters"},[_vm._l((_vm.headers),function(header,i){return _c('td',{key:i},[(header.filter)?_c('data-table-filter',{staticClass:"my-3",attrs:{"filter":header.filter},on:{"onChange":_vm.onFilterChange}}):_vm._e()],1)}),(_vm.showActions)?_c('td'):_vm._e()],2)]},proxy:true}:null,(_vm.showActions)?{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},_vm._l((_vm.filteredActions),function(action,i){return _c('v-tooltip',{key:i,attrs:{"disabled":!action.tooltip,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"fab":"","x-small":"","outlined":"","loading":_vm.isBusyAction(action, item),"color":action.color ? action.color : 'primary',"disabled":_vm.disabled(action, item)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.performAction(action, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.tooltip ? action.tooltip : ""))])])}),1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }